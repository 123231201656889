<template>
  <div class="container">
    <div class="container_block">
      <div class="container_block_info">
        <div class="container_block_info_leftpics">
          <div class="container_block_info_leftpics_bigImg">
            <template v-for="(item,index) in goods.images">
              <el-image
                v-if="index===0"
                :key="item"
                :src="item.url"
                alt=""
              />
            </template>

          </div>
          <div class="container_block_info_leftpics_smallImgs">
            <el-carousel
              type="card"
              indicator-position="none"
              height="40px"
              arrow="always"
            >
              <el-carousel-item
                v-for="item in goods.images"
                :key="item"
                justify="center"
              >
                <img
                  :src="item.url"
                  alt=""
                >
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="container_block_info_rightinfo">
          <div class="container_block_info_rightinfo_title">{{goods.name}}</div>

          <div
            class="container_block_info_rightinfo_priceinfo"
            v-if="goods.payType==='1'"
          >
            <div class="container_block_info_rightinfo_priceinfo_left"><span> &yen;{{goods.isRebate==="1" ? goods.originalPrice : goods.discountPrice}}</span><span></span></div>
            <div class="container_block_info_rightinfo_priceinfo_right"><span v-if="goods.vlActivityLengthUserrange != null && goods.isRebate==='0'">{{goods.vlActivityLengthUserrange.vipRange}}星会员享{{goods.vlActivityLengthUserrange.goodDiscount}}折优惠</span><span>{{goods.buyCount}}人兑换{{goods.exchangeNum}}{{goods?.vlUnit?.unit}}</span></div>
          </div>

          <div
            class="container_block_info_rightinfo_priceinfo"
            v-else-if="goods.payType==='2'"
          >
            <div class="container_block_info_rightinfo_priceinfo_left"><span>{{goods.score}}积分</span><span></span></div>
            <div class="container_block_info_rightinfo_priceinfo_right" ><span v-if="goods.vlActivityLengthUserrange != null && goods.isRebate==='0'">{{goods.vlActivityLengthUserrange.vipRange}}星会员享{{goods.vlActivityLengthUserrange.goodDiscount}}折优惠</span><span>{{goods.buyCount}}人兑换{{goods.exchangeNum}}{{goods?.vlUnit?.unit}}</span></div>
          </div>
          <div
            class="container_block_info_rightinfo_priceinfo"
            v-else-if="goods.payType==='3'"
          >
            <div class="container_block_info_rightinfo_priceinfo_left"><span> &yen;{{goods.isRebate==="1" ? goods.originalPrice : goods.discountPrice}} + {{goods.score}}积分</span><span></span></div>
            <div class="container_block_info_rightinfo_priceinfo_right" ><span v-if="goods.vlActivityLengthUserrange != null && goods.isRebate==='0'">{{goods.vlActivityLengthUserrange.vipRange}}星会员享{{goods.vlActivityLengthUserrange.goodDiscount}}折优惠</span><span>{{goods.buyCount}}人兑换{{goods.exchangeNum}}{{goods?.vlUnit?.unit}}</span></div>
          </div>

          <div class="container_block_info_rightinfo_supportTag"> <span><img
                src="../../assets/images/supportb.png"
                alt=""
              ></span> 赞助商</div>
          <div class="container_block_info_rightinfo_supportCompany"> {{goods.support}}</div>
          <div class="container_block_info_rightinfo_cart">
            <span class="container_block_info_rightinfo_cart_stepper">
              <el-input-number
                v-model="goods.num"
                :min="1"
                :max="999"
                controls-position="right"
                size="large"
                style="width:80px;"
              />
            </span>

            <span class="container_block_info_rightinfo_cart_addbtn">
              <el-button
                type="primary"
                @click="addToCart"
              >加入购物车</el-button>
            </span>
            <span class="container_block_info_rightinfo_cart_addbtn">
              <el-button
                type="warning"
                @click="toPay"
              >立即下单</el-button>
            </span>
          </div>
        </div>
      </div>
      <div class="container_block_divider">
        <el-divider />
      </div>

      <div class="container_block_content">
        <div class="container_block_content_title"> <span><img
              src="../../assets/images/guige.png"
              alt=""
            ></span> 商品详情</div>
        <div class="container_block_content_detail" v-html="goods.description"> </div>
      </div>
    </div>
    <div
      class="container_cart"
      @click="toCart"
    >
      <el-badge
        :value="cartNum"
        :max="999"
        class="item"
      >
        <div class="container_cart_wrap"><img
            src="../../assets/images/cart.png"
            alt=""
          ></div>
      </el-badge>
    </div>
  </div>
</template>

<script setup name="ShopDetail">
import { reactive, ref, toRefs } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { getShopGoodsById, addCart, getCart } from "@/api/shop"
const route = useRoute();
const router = useRouter();
const goodsId = route.query.id;
const data = reactive({ goods: {} })
const cartList = ref([])
const cartNum = ref(1)
const active = ref(0)
const total = ref(0)
active.value = 0
total.value = 3
const payList = ref([]);
//获取商品详情
const getGoodsByid = async (id) => {
  let result = await getShopGoodsById(id);
  data.goods = result.data
  data.goods.num = 1;
}
getGoodsByid(goodsId);

//获取购物车列表
const getCartList = async () => {
  let result = await getCart();
  console.log("cartlist===", result)
  cartList.value = result.rows
  cartNum.value = cartList.value.length
}
getCartList();

const { goods } = toRefs(data)

//加入购物车
//let goodsNum = 1;
const addToCart = async () => {
  let cart = {
    goodsId: goods.value.id,
    num: goods.value.num ?? 1
  }
  let result = await addCart(cart);
  if (result.code === 200) {
    console.log(result)
    getCartList();
  }
  return result.data
}
//到购物车界面
const toCart = () => {
  console.log(111)
  router.push({ path: "shopCart" })
  //router.push({ name: "ShopCart" })
}

//到支付界面
const toPay = async () => {
  let cartGoods = await addToCart();
  cartGoods.check = true
  console.log("cartGoods===", cartGoods)
  payList.value.push(cartGoods)
  let price = 0;
  let score = 0;
  console.log("payList.value", payList.value)
  for (let item of payList.value) {
    console.log("item.num===", item)
    if (item?.vlShopGoods?.payType === "1") {
      price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
    } else if (item?.vlShopGoods?.payType === "2") {
      score += item?.vlShopGoods?.score * item.num
    } else {
      price += (item?.vlShopGoods?.isRebate === "1" ? item?.vlShopGoods?.originalPrice : item?.vlShopGoods?.discountPrice) * item.num
      score += item?.vlShopGoods?.score * item.num
    }
  }
  if (payList.value != null && payList.value.length > 0) {
    console.log("totalprice===", price)
    console.log("totalscore===", score)
    console.log("paylist===", JSON.stringify(payList.value))
    router.push({ name: "ShopPay", params: { list: JSON.stringify(payList.value), totalPrice: price, totalScore: score } })
  }

}

</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 30px;
  &_block {
    margin: 0 auto;
    padding: 30px 30px 30px 30px;
    width: 65%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_info {
      width: 100%;
      display: flex;
      flex-direction: row;
      &_leftpics {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        &_bigImg {
        }
        &_bigImg img {
          height: 375px;
        }
        &_smallImgs {
        }
        &_smallImgs img {
          height: 60px;
          vertical-align: middle;
          line-height: 60px;
        }
      }
      &_rightinfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &_title {
          font-size: 20px;
          color: #212121;
          font-weight: bold;
        }
        &_priceinfo {
          margin-top: 40px;
          margin-bottom: 50px;
          padding: 20px 20px;
          border-radius: 6px;
          background-color: rgba(245, 245, 245, 100);
          width: 95%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &_left {
          }
          &_right {
          }
        }
        &_supportTag {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 16px;
          color: #212121;
        }
        &_supportTag img {
          height: 16px;
          margin-right: 5px;
        }
        &_supportCompany {
          margin-top: 15px;
          font-size: 18px;
          color: #212121;
        }
        &_cart {
          margin-top: 76px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          &_stepper {
            margin-right: 15px;
          }
          &_addbtn {
            margin-right: 30px;
          }
        }
      }
    }
    &_divider {
      width: 100%;
      margin: 10px 0 10px 0;
    }
    &_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &_title {
        font-size: 16px;
        color: #212121;
      }
      &_title img {
        height: 16px;
        vertical-align: middle;
        margin-left: 5px;
      }
      &_detail {
        margin-top: 20px;
        font-size: 16px;
        text-align: left;
      }
    }
  }
  &_cart {
    position: fixed;
    top: 70%;
    right: 80px;
    &_wrap {
      background: #fff;
      border-radius: 50px;
      padding: 30px 30px;
    }
    &_wrap img {
      height: 30px;
    }
  }
}
// :deep(.el-input__inner){
//   width: 60px;
// }
:deep(.el-button--primary) {
  width: 240px;
  height: 60px;
  color: #3b2f00;
  background-color: #fff;
  border: 1px solid rgba(255, 153, 89, 100);
  font-size: 20px;
}
:deep(.el-button--warning) {
  width: 240px;
  height: 60px;
  color: #3b2f00;
  background-color: #ffcf1a;
  border: 1px solid #ffcf1a;
  font-size: 20px;
}
.el-carousel__item img {
  opacity: 0.75;
  line-height: 60px;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: #d3dce6;
}
</style>
